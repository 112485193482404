import React from 'react'
import { Link } from 'react-router-dom'

const HomeAbout = () => {
    return (
        <>
            <section className='after_slider_content' id='aboutus'>
                <div className="container">
                    <div className="row pl-0 pr-0">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 pr-0 ">
                            <div className="main_msg_container title_heading">
                                <h1 >About Us</h1>
                                <div className="principal_msg_content" >
                                    <p>JMI ALUMNI ASSOCIATION, VICTORIA, AUSTRALIA is an enlightened, resourceful and dynamic group of individuals residing in the state of Victoria who share a common vision of promoting the values of our institution, Jamia Millia Islamia. Members of the Association dearly uphold the principles of universal education, sharing knowledge and skills, supporting and caring for each other, responding to the needs of the broader community with empathy and compassion; both at a local level as well as overseas, particularly during unforeseen and unfortunate circumstances affecting fellow humans in anyway. Association members fully embrace the spirit of giving back to the community in various capacities as well as promoting and preserving human rights globally....
                                        <Link to="/aboutus" className='ReadMoreOSA text-primary'>Read More</Link> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeAbout
