import React from 'react'
import PageHeader from '../../Components/PageHeader'

const AlumniPicnic = () => {
    return (
        <>
            <section className='history_of_osa'>
                <div className="about_osa_layer">
                    <PageHeader heading="Alumni Picnic" subheading="News"/>
                    <div className="about_main_container">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="newsdetails_img">
                                        <img src="assets/images/article3.png" alt="" />
                                    </div>
                                </div>
                                <div className="col-md-7 pl-2">
                                    <div className="aboutschool_text">
                                        <h4 className='news_title'>Alumni Picnic</h4>
                                        <h6 className='mb-4'>Bringing alumni members together.</h6>
                                        <p>We at Jamia Alumni Association, Victoria believes that picnics are a form of strengthening relationships. Face to face interaction helps to form a connection. In February 2021 we organised a picnic on a beautiful sunny day with lovely décor and some memorable food. We all walked away with some lovely memories and Samosa anecdotes that continue till date.
                                            <br />
                                            We at, Jamia Alumni Association Victoria attest in picnics as a way to empower our group and encourage civic participation. Picnics have a way to reinforce the idea that a community can organize itself in such a way that is beneficial for everyone involved.
                                            
                                            The concept of picnic brought the entire group together at a finer level adding layers of laughter, food talks and of course planning of next get together.


                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default AlumniPicnic
