import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import required modules
import { Autoplay, EffectFade, Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom/dist';


const Slider = () => {
    return (
        <>
            <section id="slider-area">
                <div className="slider-active-wrap owl-carousel  text-center text-md-start" id="slider">
                    <div className="single-slide-wrap ">
                        <Swiper
                            grabCursor
                            spaceBetween={5} navigation={true}
                            autoplay={{
                                delay: 2800,
                                disableOnInteraction: false,
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            effect={'fade'} loop={true}
                            modules={[Autoplay, EffectFade, Navigation]}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <div className="slider_img_two" style={{ backgroundImage: 'url(https://upload.wikimedia.org/wikipedia/commons/f/f2/An_Inside_view_of_JMI_Centenary_Gate.jpg)' }}>
                                    <div className="slider_layer"></div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="slider_img_two" style={{ backgroundImage: 'url(assets/images/slider2.png)' }}></div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="slider_img_two" style={{ backgroundImage: 'url(https://thelucknowtribune.org/wp-content/uploads/2024/03/Jamia.webp)' }}></div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="slider_img_two" style={{ backgroundImage: 'url(https://i.pinimg.com/originals/0d/31/f7/0d31f78b95547af07d521c62fab4a469.jpg)' }}></div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
                <div className="social-networks-icon d-none">


                    <ul>
                        <li>
                            <Link to="https://www.facebook.com/people/Jamia-Millia-Islamia-Alumni-Victoria/100069989174382/" target='_blank'>
                                <i className="fa-brands fa-facebook-f"></i> <span>Facebook</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="https://wa.link/oxd6wz" target="_blank">
                                <i className="fab fa-whatsapp" /> <span>WhatsApp</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={''}>
                                <i className="fab fa-instagram" /> <span>Instagram</span>
                            </Link>
                        </li>
                    </ul>
                </div>

            </section>

        </>
    )
}

export default Slider
