import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Container, Row, Col } from 'reactstrap'

const Header = () => {
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <>
            <header >
                {/* <div className="top_navbar">
                    <Container >
                        <Row className='top_navbars'>
                            <Col lg={12} md={12} sm={12} className='text-start p-0 m-0'>
                                <div className="top_navbar_contain">
                                    <span className='fs-5'> <i className='fa fa-facebook'></i></span>&nbsp;
                                    <span className='fs-5'> <i className='fa fa-envelope'></i>&nbsp;&nbsp;   abc123@gmail.com   </span>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div> */}
            </header>
            <nav className="navbar navbar-expand-lg navbar-light stickey_navbar" id='desktop_navbar_menu'>
                <div className="container">
                    <div className="nav_logo_here">
                        <Link to="/" onClick={() => scrollToSection('slider')} className='headerLogo' >
                            <img src="/assets/images/newlogo.png" alt="logo" />
                            <span className='jamiaAlumni_name_here'>  JMI Alumni Association of
                                Victoria, Australia</span>
                        </Link>&emsp;&emsp;
                    </div>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ml-5 ms-auto">
                            <li className="nav-item">
                                <Link className="nav-link " aria-current="page" to="/" onClick={() => scrollToSection('slider')} >
                                    Home
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/aboutus">
                                    About Us
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={"/#news"} onClick={() => scrollToSection('news')}>
                                    News
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/#gallery" onClick={() => scrollToSection('events')} >
                                    Gallery
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/#team" onClick={() => scrollToSection('team')}>
                                    Our Team
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/contactus">
                                    Contact Us
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Header
