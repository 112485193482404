import React from 'react'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "../Components/Footer";
import Home from "../Pages/Home";
import SidebarMenu from '../Components/SidebarMenu';
import Navbar from '../Components/Navbar';
import Error404Page from '../Pages/Error404Page';
import AboutUs from '../Pages/AboutUs';
import ScholarshipFund from '../Pages/News/ScholarshipFund';
import CommunityFundraiser from '../Pages/News/CommunityFundraiser';
import AlumniPicnic from '../Pages/News/AlumniPicnic';
import ContactUs from '../Pages/ContactUs';
import Header from '../Components/Header';
import AllNews from '../Pages/News/AllNews';
import NewsDetails from '../Pages/News/NewsDetails';

const Allroutes = () => {
    return (
        <>
            <BrowserRouter>
                <Header />
                <Navbar />
                {/* <SidebarMenu /> */}
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/aboutus" element={<AboutUs />} />
                    <Route path="/news" element={<AllNews />} />
                    <Route path="/scholarshipFund" element={<ScholarshipFund />} />
                    <Route path="/communityFundraiser" element={<CommunityFundraiser />} />
                    <Route path="/alumniPicnic" element={<AlumniPicnic />} />
                    <Route path="/contactus" element={<ContactUs />} />
                    <Route path="/news_detail/:id" element={<NewsDetails />} />
                    <Route path="/*" element={<Error404Page />} />
                </Routes>
                <Footer />
            </BrowserRouter>
        </>
    )
}

export default Allroutes
