import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom';

const Footer = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    upper();
  }, [location.pathname]);
  const upper = () => {
    window.scrollTo(0, 0);
  }
  // const [currentYear, setCurrentYear] = useState();

  return (
    <>
      <footer id="footer-area">
        <div className="footer-widget section-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                <div className="school_name_here">
                  <div className="school_logo">
                    <center>
                      <Link to="/"><img src="/assets/images/newlogo.png" alt="Logo" onClick={upper} />
                      </Link>
                    </center>
                  </div>
                  <div className="school_name">
                    <h1 ><Link to="/" onClick={upper} ><span>JMI Alumni Association of  </span></Link></h1>
                    <h2><Link to="/" onClick={upper} ><span>Victoria, Australia</span></Link></h2>
                  </div>
                  <div className="some_content_here mt-3">
                    <p>JMI Alumni Association of Victoria, Australia Incorporated is a registered body under Section 8
                      of Association <br /> Reform Act 2012. The Association was formally registered with Consumer <br /> Affairs Victoria on 21 May 2021.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                <div className="usefull_footerlink">
                  <ul className='usefull_links'>
                    <li>
                      <Link to="https://www.jmi.ac.in/" target='_blank' className='text-warning text-decoration-underline'>Jamia Millia Islamia Website</Link>
                    </li>
                    <li>
                      <Link to="https://aajmi.co.in/" target='_blank' className='text-warning text-decoration-underline'>JMI Alumni Website</Link>
                    </li>
                  </ul>
                </div>
              </div>

            </div>

          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="footer-bottom-text">
                  <p>©  Copyright  {new Date().getFullYear()}- JMI Alumni Association Of Victoria, Australia | Powered By - <Link to="https://onebigbit.com/" target='_blank'>OneBigBit Technologies Private Limited</Link></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

    </>
  )
}

export default Footer
