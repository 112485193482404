import React, { useRef, useState } from 'react'
import PageHeader from '../Components/PageHeader'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import { Form, FormFeedback, Input, Label } from 'reactstrap';
import { postSubmitForm } from '../helpers/forms_helper';
import showToast from '../helpers/show_toast';

const ContactUs = () => {
  const formref = useRef();
  const [formdata, setFormdata] = useState({
    name: '',
    mobile: '',
    email: '',
    subject: '',
    message: ''
  });
  const handleOnchange = (e) => {
    const { name, value } = e.target;
    setFormdata((pre) => ({ ...pre, [name]: value }))
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (e.target.checkValidity()) {
      try {
        const payload = {
          name: formdata.name,
          mobile: formdata.mobile,
          email: formdata.email,
          subject: formdata.subject,
          message: formdata.message
        };
        let url = "https://api.jmialumnivic.com.au/contacts/insert";
        let response = await postSubmitForm(url, payload);
        if (response.status === 1) {
          setFormdata({
            name: '',
            mobile: '',
            email: '',
            subject: '',
            message: ''
          });
          showToast('Thank you for contacting us! We will get back to you soon', 'success');
          formref.reset();
        } else {
          showToast('Something went wrong!', 'error')
        }
      } catch (err) {
        console.log(err)
      }
    } else {
      e.target.classList.add('was-validated');
    }
  }
  return (
    <>
      <section className='history_of_osa'>
        <div className="about_osa_layer">
          <PageHeader heading="Contact Us" />
          <div className="container">
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <div className="conatactus_main_container">
                  <div className="card">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="contactus_form">
                          <h3>Send Message</h3>
                          <Form onSubmit={handleSubmit} noValidate className='suggestionpage contactform'>
                            <div className="mb-3">
                              <Label htmlFor="name" >  Name <span className='text-danger'>*</span> </Label>
                              <Input type="text" onChange={handleOnchange} value={formdata.name} required name="name" id="name" placeholder='Enter Name' />
                              <FormFeedback>Name is required</FormFeedback>
                            </div>
                            <div className="mb-3">
                              <Label htmlFor="mobile" >
                                Mobile No.<span className='text-danger'>*</span>
                              </Label>
                              <Input type="text" maxLength={10} minLength={10} name="mobile" required value={formdata.mobile} onChange={handleOnchange} id="mobile" placeholder='Enter Mobile No.' />
                              <FormFeedback>Mobile No. is required</FormFeedback>

                            </div>
                            <div className="mb-3">
                              <Label htmlFor="exampleInputEmail1" >
                                Email <span className='text-danger'>*</span>
                              </Label>
                              <Input
                                type="email"
                                name="email"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp" required onChange={handleOnchange} value={formdata.email}
                                placeholder='Enter Email'
                              />
                              <FormFeedback>Email is required</FormFeedback>
                            </div>
                            <div className="mb-3">
                              <Label htmlFor="Subject" >
                                Subject <span className='text-danger'>*</span>
                              </Label>
                              <Input
                                type="text"
                                required onChange={handleOnchange} value={formdata.subject}
                                id="Subject"
                                name='subject'
                                placeholder='Enter Subject'
                              />
                              <FormFeedback>Subject is required</FormFeedback>


                            </div>
                            <div className="mb-3">
                              <Label htmlFor="msg" >
                                Message <span className='text-danger'>*</span>
                              </Label>
                              <Input type="textarea" name="message" onChange={handleOnchange} value={formdata.message} required placeholder='Enter Message Here' ></Input>
                              <FormFeedback>Message is required</FormFeedback>
                            </div>
                            <div className="mb-3 text-center mt-5">
                              <button type="submit" className="register_btn btn btn-primary ">
                                Send <i className="fa fa-paper-plane" aria-hidden="true"></i>
                              </button>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ContactUs
