import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import showToast from '../helpers/show_toast';
import { getSubmitForm } from '../helpers/forms_helper';

const NewsSection = () => {
    useEffect(() => {
        fetch_data();
    }, []);
    const [allData, setAllData] = useState([]);
    const fetch_data = async () => {
        // let url = process.env.VITE_APP_BASEURL + "news/getall";
        let url = "https://api.jmialumnivic.com.au/news/get_last6";
        const response = await getSubmitForm(url, '')
        if (response && response.status === 1) {
            setAllData(response.data)
        } else {
            showToast(response.error, "error")
        }
    };
    return (
        <>
            <section className='newsSection' id='news'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 text-center">
                            <div className="new_joiners_heading title_heading mb-5 pb-4">
                                <h1> News</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {allData && allData.length > 0 ?
                            allData.map((data, idx) => {

                                return (
                                    <>
                                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={idx}>
                                            <div className="card news_cards">
                                                <img className="card-img-top" src={data?.image_urls ? data.image_urls[0] : ''} alt="Card image cap" />
                                                <div className="card-body">
                                                    <h5 className="card-title"><b>{data.title}</b></h5>
                                                    <p className="card-text">{data.short_des}</p>
                                                    <Link to={`/news_detail/${btoa(data._id)}`} className="text-primary ">Read More <i className="fa-solid fa-chevron-right"></i></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                            : ''}

                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-3 text-center mt-5">
                                <Link to="/news" className="register_btn btn btn-primary text-light">
                                    View More
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default NewsSection
