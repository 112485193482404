import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Autoplay } from 'swiper/modules';
import { getSubmitForm } from '../helpers/forms_helper';
import showToast from "../helpers/show_toast";
import { Card, CardBody, CardImg, CardTitle, Row } from 'reactstrap';

const NewJoiners = () => {
    useEffect(() => {
        fetch_data();
    }, []);
    const [allData, setAllData] = useState([]);
    const fetch_data = async () => {
        // let url = process.env.VITE_APP_BASEURL + "news/getall";
        let url = "https://api.jmialumnivic.com.au/teams/getall";
        const response = await getSubmitForm(url, '')
        if (response && response.status === 1) {
            setAllData(response.data)
        } else {
            showToast(response.error, "error")
        }
    };
    return (
        <>
            <section className='newjoiners_section my-5' id='team'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 text-center">
                            <div className="new_joiners_heading title_heading">
                                <h1> Leadership Team</h1>
                            </div>
                        </div>
                    </div>
                    <Row className='data_row_here'>
                        {allData && allData.length > 0 ?
                            allData.map((data, idx) => {
                                return (
                                    <>
                                        <div className="col-lg-2 col-md-4 col-sm-4 col-6 " key={idx}>
                                            <Card className="new_joiners_card ">
                                                <CardImg src={data.image_url ? data.image_url : '/noimg.jpg'} alt="" />
                                                <CardBody className='text-center px-1  pb-0'>
                                                    <div className="newjoiner_details">
                                                        <h6>{data.name}</h6>
                                                        <p className='text-center'>{data.designation}</p>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </div>
                                    </>
                                )
                            })
                            : ''}
                    </Row>
                </div>
                <div className='blue_bgline d-none'>
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-12 col-md-12 col-sm-12 text-center d-none">
                                <div className="new_joiner_swiper">
                                    <Swiper
                                        grabCursor={true}
                                        spaceBetween={10}
                                        pagination={{
                                            clickable: true,
                                        }}
                                        breakpoints={{
                                            320: {
                                                slidesPerView: 1,
                                            },
                                            480: {
                                                slidesPerView: 1,
                                            },
                                            640: {
                                                slidesPerView: 2,
                                            },
                                            768: {
                                                slidesPerView: 2,
                                            },
                                            1024: {
                                                slidesPerView: 4,
                                            },
                                        }}
                                        rewind={true} autoplay={{
                                            delay: 2000,
                                            disableOnInteraction: false,
                                        }} loop={true} modules={[Autoplay]} className="mySwiper">
                                        {allData && allData.length > 0 ?
                                            allData.map((data, idx) => {

                                                return (
                                                    <>
                                                        <SwiperSlide key={idx}>
                                                            <div className="new_joiners_circle">
                                                                <img src={data.image_url ? data.image_url : '/noimg.jpg'} alt="" />
                                                                <div className="newjoiner_details">
                                                                    <h5>{data.name}</h5>
                                                                    <p className='text-center'>{data.designation}</p>
                                                                </div>
                                                            </div>
                                                        </SwiperSlide>
                                                    </>
                                                )
                                            })
                                            : ''}
                                        {/* <SwiperSlide>
                                            <div className="new_joiners_circle">
                                                <img src="assets/images/Akhtar_Shamim.png" alt="" />
                                                <div className="newjoiner_details">
                                                    <h5>Akhtar Shamim </h5>
                                                    <p className='text-center'>Vice President</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="new_joiners_circle">
                                                <img src="assets/images/Shoaib_Alam.png" alt="" />
                                                <div className="newjoiner_details">
                                                    <h5>Shoaib Alam</h5>
                                                    <p className='text-center'>Treasurer</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="new_joiners_circle">
                                                <img src="assets/images/Yasmin_Siddiqui.png" alt="" />
                                                <div className="newjoiner_details">
                                                    <h5>Yasmin Siddiqui</h5>
                                                    <p className='text-center'>General Secretary</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="new_joiners_circle">
                                                <img src="assets/images/Nazish_Fatima.png" alt="" />
                                                <div className="newjoiner_details">
                                                    <h5>Nazish Fatima</h5>
                                                    <p className='text-center'>Media & Communication</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="new_joiners_circle">
                                                <img src="assets/images/saad.png" alt="" />
                                                <div className="newjoiner_details">
                                                    <h5>Saad Ahmad</h5>
                                                    <p className='text-center'>Advisor</p>
                                                </div>
                                            </div>
                                        </SwiperSlide> */}
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default NewJoiners
