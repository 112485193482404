import React, { useEffect, useState } from 'react'
import PageHeader from '../../Components/PageHeader'
import { useParams } from 'react-router-dom'
import showToast from '../../helpers/show_toast';
import { getSubmitForm, postSubmitForm } from '../../helpers/forms_helper';
import DOMPurify from 'dompurify';
import moment from 'moment';
import { Card, CardImg, Col, Row } from 'reactstrap';
import LightGallery from 'lightgallery/react';
// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-rotate.css';
import 'lightgallery/css/lg-autoplay.css';
// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgRotate from 'lightgallery/plugins/rotate';
import lgAutoplay from 'lightgallery/plugins/autoplay';

const NewsDetails = () => {
    const params = useParams();
    useEffect(() => {
        const id = atob(params.id);
        fetch_data(id);
    }, []);
    const [allData, setAllData] = useState();
    const [desc, setDesc] = useState();

    const fetch_data = async (id) => {
        const payload = { id }
        // let url = process.env.VITE_APP_BASEURL + "news/getall";
        let url = "https://api.jmialumnivic.com.au/news/getBy_Id";
        const response = await postSubmitForm(url, payload)
        if (response && response.status === 1) {
            setAllData(response.data);
            const sanitizedHtmlContent = DOMPurify.sanitize(response.data.description);
            setDesc(sanitizedHtmlContent)
        } else {
            showToast(response.error, "error")
        }
    };
    return (
        <>
            <section className='history_of_osa'>
                <div className="about_osa_layer">
                    <PageHeader heading={allData?.title} subheading="News" />
                    <div className="about_main_container">
                        <div className="container">
                            {allData ?
                                <>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="newsdetails_img">
                                                <img src={allData?.image_urls[0]} alt="" />
                                            </div>
                                        </div>
                                        <div className="col-md-7 pl-2">
                                            <div className="aboutschool_text">
                                                <h4 className='news_title mb-0'>{allData.title}</h4>
                                                <small className='text-muted ' style={{ fontWeight: '600' }}>{moment(allData.createdAt).format('DD-MMM-YYYY')}</small>
                                                <h6 className='mb-4 mt-3'>{allData.short_des}</h6>
                                                <div className="desc">
                                                    <div dangerouslySetInnerHTML={{ __html: desc }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br /><br />

                                    <LightGallery speed={500}
                                        plugins={[lgThumbnail, lgZoom, lgRotate, lgAutoplay]}
                                        pause={2000}
                                    >
                                        {allData.image_urls.length > 1 ?
                                            allData.image_urls.map((img, id) => (
                                                <>
                                                    <a
                                                        key={id}
                                                        data-src={img} className='data_card'>
                                                        <Card className='gallery_imgs'>
                                                            <center><CardImg src={img} alt={allData.title} /></center>
                                                        </Card>
                                                    </a>
                                                </>
                                            )) : ''}

                                    </LightGallery>
                                </>
                                : ''}
                        </div>

                    </div>
                </div>
            </section >
        </>
    )
}

export default NewsDetails
