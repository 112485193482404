import React from 'react'
import PageHeader from '../../Components/PageHeader'

const ScholarshipFund = () => {
    return (
        <>
            <section className='history_of_osa'>
                <div className="about_osa_layer">
                <PageHeader heading="Scholarship fund 2020" subheading="News"/>
                <div className="about_main_container">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="newsdetails_img">
                                        <img src="assets/images/article1.png" alt="" />
                                    </div>
                                </div>
                                <div className="col-md-7 pl-2">
                                    <div className="aboutschool_text">
                                        <h4 className='news_title'>Scholarship fund 2020</h4>
                                        <h6 className='mb-4'>Raising for the deserving one.  </h6>
                                       
                                        <p>Dayare shauk mera Shehre aarzo mera “this is the land of my hopes - this is the land of my dreams”.
                                            <br />
                                            To commemorate 100 glorious years of our beloved University we established a scholarship fund to help the underprivileged girls studying at the university. Our celebration was graced with the esteemed presence of Hon. Prof. Najma Akhtar (Vice Chancellor JMI), Ms Arfa Khanum Sherwani and RJ Naved.

                                        </p>
                                        <p>We feel so proud to mention here that during the live zoom celebration session we achieved the target amount set for the scholarship scheme.
                                            <br />
                                            We are also grateful to entire organising committee for organising such a wonderful and memorable event.


                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default ScholarshipFund
