import React from 'react'
import Slider from '../Components/Slider'
import GallerySection from '../Components/GallerySection'
import NewJoiners from '../Components/NewJoiners'
import HomeAbout from '../Components/HomeAbout'
import Popup from '../Components/Modals/Popup'
import NewsSection from '../Components/NewsSection'

const Home = () => {
    return (
        <>
            {/* <Popup /> */}
            <div id="main_content" className="main-content">
                <Slider />
                <HomeAbout />
                <NewsSection />
                <GallerySection />
                <NewJoiners />
            </div>
        </>
    )
}

export default Home
