import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import './Mobilemenu.css'

const Navbar = () => {
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isSticky, setSticky] = useState(false);
    const [click, setClick] = useState(false);
    const [subMenuOpen, setSubMenuOpen] = useState(false);

    const handleScroll = () => {
        setSticky(window.scrollY > 100);
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const toggleAudio = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };
    useEffect(() => {
        toggleAudio();
        return () => {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
            }
        };
    }, []);

    const handleClick = () => {
        setClick(!click);
        document.body.style.overflow = click ? 'auto' : 'hidden';
    };
    const Close = () => setClick(false);

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <>
            <div className="mobile_menu_visible">
                <header className={` sticky-menu ${isSticky ? 'sticky' : ''} site-header`}>
                    <div className="mobile-menu-new">
                        <div className={click ? "main-container" : ""} onClick={() => Close()} />
                        <nav className="navbar" onClick={e => e.stopPropagation()}>
                            <div className="nav-container">
                                <Link to="/" rel="home" className="main-logo">
                                    <img
                                        id="logo_header"
                                        src="/assets/images/newlogo.png" className="sitemain_logo"
                                        alt="JMI Alumni"
                                    />
                                </Link>
                                <div className="less_small">
                                    <div className="schoolname_txt">
                                        <h6><Link to="/">&nbsp; JMI Alumni Association of <br />
                                            &nbsp; Victoria, Australia</Link></h6>
                                    </div>
                                </div>
                                <div className="for_better_view">
                                    <div className="schoolname_txt">
                                        <h6><Link to="/">&nbsp; JMI Alumni Association of 
                                            Victoria, Australia</Link></h6>
                                    </div>
                                </div>

                                <ul className={click ? "nav-menu active" : "nav-menu"}>
                                    <li className="nav-item" onClick={() => scrollToSection('slider')} >
                                        <Link
                                            exact
                                            to="/"
                                            activeClassName="active"
                                            className="nav-links"
                                            onClick={click ? handleClick : null}
                                        >
                                            <i className='fa fa-home'></i>  <span>Home</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item" >
                                        <Link
                                            exact
                                            to="/aboutus"
                                            activeClassName="active"
                                            className="nav-links"
                                            onClick={click ? handleClick : null}
                                        >   <i className="fa-solid fa-info-circle"></i>

                                            <span>About Us</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item" onClick={() => scrollToSection('news')}>
                                        <Link
                                            exact
                                            to="/"
                                            activeClassName="active"
                                            className="nav-links"
                                            onClick={click ? handleClick : null}
                                        >
                                            <i className="fa-solid fa-newspaper"></i>
                                            <span>News</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item" onClick={() => scrollToSection('events')}>
                                        <Link
                                            exact
                                            to="/"
                                            activeClassName="active"
                                            className="nav-links"
                                            onClick={click ? handleClick : null}
                                        >
                                            <i className="fa-solid fa-award"></i>

                                            <span>Events</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item" onClick={() => scrollToSection('team')}>
                                        <Link
                                            exact
                                            to="/"
                                            activeClassName="active"
                                            className="nav-links"
                                            onClick={click ? handleClick : null}
                                        >
                                            <i className="fa-solid fa-users"></i>

                                            <span>Our Team</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link
                                            exact
                                            to="/contactus"
                                            activeClassName="active"
                                            className="nav-links"
                                            onClick={click ? handleClick : null}
                                        >
                                            <i className="fa-solid fa-address-card"></i>
                                            <span>Contact Us</span>
                                        </Link>
                                    </li>
                                </ul>
                                <div className="nav-icon" onClick={handleClick}>
                                    <i className={click ? "fa fa-times" : "fa fa-bars"}></i>
                                </div>
                            </div>
                        </nav>
                    </div>
                </header>
            </div>
        </>
    )
}

export default Navbar
