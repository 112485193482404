import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Autoplay } from 'swiper/modules';
import React, { useEffect, useState } from 'react'
import showToast from '../helpers/show_toast';
import { getSubmitForm } from '../helpers/forms_helper';
import { Link } from 'react-router-dom';

const GallerySection = () => {
    useEffect(() => {
        fetch_data();
    }, []);
    const [allData, setAllData] = useState([]);
    const fetch_data = async () => {
        // let url = process.env.VITE_APP_BASEURL + "news/getall";
        let url = "https://api.jmialumnivic.com.au/news/get_last15";
        const response = await getSubmitForm(url, '')
        if (response && response.status === 1) {
            setAllData(response.data)
        } else {
            showToast(response.error, "error")
        }
    };
    return (
        <>
            <section className='gallerySection bg-light' id='events'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 ml-0 pl-0 pr-0">
                            <div className="gallery_section pb-5">
                                <div className="gallery_heading text-center  title_heading mt-5">
                                    <h1> Gallery</h1>
                                    <h6 className='mt-4'>The annual dinner for Jamia's 102nd Foundation Day hosted at Waterview Boulevard, Craigeburn.</h6>
                                </div>
                                <div className="gallery_swiper mb-5">
                                    <Swiper
                                        grabCursor={true}
                                        spaceBetween={10}
                                        navigation={true}
                                        loop={true}
                                        breakpoints={{
                                            320: {
                                                slidesPerView: 1,
                                            },
                                            480: {
                                                slidesPerView: 1,
                                            },
                                            640: {
                                                slidesPerView: 2,
                                            },
                                            768: {
                                                slidesPerView: 3,
                                            },
                                            1024: {
                                                slidesPerView: 3,
                                            },
                                        }}
                                        
                                        rewind={true}
                                        autoplay={{
                                            delay: 2900,
                                            disableOnInteraction: false,
                                        }}
                                        modules={[Navigation, Autoplay]} className="mySwiper">
                                        {allData && allData.length > 0 ?
                                            allData.map((data, idx) => {
                                                return (
                                                    <>
                                                        <SwiperSlide key={idx}>
                                                            <Link to={`/news_detail/${(btoa(data._id))}`}>
                                                                <div className="galler_img">
                                                                    <img src={data.image_urls[0]} className='img-fluid' alt="" />
                                                                </div>
                                                            </Link>
                                                        </SwiperSlide>
                                                    </>
                                                )
                                            }) : ""}
                                        {/* <SwiperSlide>
                                            <div className="galler_img">
                                                <img src="assets/images/le2.jpg" className='img-fluid' alt="" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="galler_img">
                                                <img src="assets/images/le3.jpg" className='img-fluid' alt="" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="galler_img">
                                                <img src="assets/images/le4.jpg" className='img-fluid' alt="" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="galler_img">
                                                <img src="assets/images/le5.jpg" className='img-fluid' alt="" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="galler_img">
                                                <img src="assets/images/le6.jpg" className='img-fluid' alt="" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="galler_img">
                                                <img src="assets/images/le7.jpg" className='img-fluid' alt="" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="galler_img">
                                                <img src="assets/images/le8.jpg" className='img-fluid' alt="" />
                                            </div>
                                        </SwiperSlide> */}
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default GallerySection
