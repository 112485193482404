import React from 'react'
import PageHeader from '../../Components/PageHeader'
import { Link } from 'react-router-dom'

const CommunityFundraiser = () => {
  return (
    <>
      <section className='history_of_osa'>
        <div className="about_osa_layer">
        <PageHeader heading="Community Fundraiser" subheading="News"/>
        <div className="about_main_container">
            <div className="container">
              <div className="row">
                <div className="col-md-5 col-sm-12 col-12">
                  <div className="newsdetails_img">
                    <img src="assets/images/artical2.jpg" alt="" />
                  </div>
                </div>
                <div className="col-md-7 pl-2 col-sm-12 col-12">
                  <div className="aboutschool_text">
                    <h4 className='news_title'>Community Fundraiser</h4>
                    <h6 className='mb-4'>Raising for the deserving one.</h6>

                    <p>Jamia Millia Islamia Alumni Association, Victoria has worked effectively in the past to provide help to the community through fund-raising in instances like Australian Bushfires 2019 and establishment of scholarships for female students at the Jamia University.</p>
                    <p>During the COVID crisis in India, the Alumni Association came together to raise funds to help underprivileged people who needed support due to COVID -19. The collected funds were used to provide medicines, supply of oxygen, online doctor consultations at the ground level. We had partnered with "Helping Hand " an NGO based in India. <Link to="https://helpinghandf.org/" target='_blank' style={{ fontWeight: '500' }}>https://helpinghandf.org</Link></p>

                  </div>
                </div>
              </div>
              <br /><br />
              <div className="row">
                <div className="col-md-12">
                  <div className="aboutschool_text">
                    <p>With support from within the Association and wider community, we were able to achieve our target of 10,000 AUD within a week's time. We extend our heartfelt gratitude to all our donors who have continued to make donations even after the target collection amount was reached. We made an initial transfer of funds to
                      <Link to="/https://www.facebook.com/HelpingHandFoundationHyderabad" target='_blank' style={{ fontWeight: '500' }}> Helping Hand Foundation</Link>  based in Hyderabad India.
                    </p>
                    <p>Considering the current pandemic plight in India we will continue with our endeavours to support the underprivileged. We will identify and collaborate with other not-for-profit organizations and will continue with our pledge to help our fellow Indians in these testing times. <br /> We encourage you to ask any questions related to the objectives of our organization and the purpose of this fundraising. We look forward to your valuable contribution and support.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default CommunityFundraiser
